<template>
	<div>
		<div class="table">
			<div class="info">
				<span>学校应用假日列表</span>
				<el-button type="primary" plain @click="goHolidayTemplate">假日模板</el-button>
			</div>
			<el-table :data="holidayData" v-loading="loading">
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="SchoolName" label="学校名称" align="center"></el-table-column>
				<el-table-column label="假日模板" align="center">
					<template slot-scope="scope">
						<div v-if="!!scope.row.tplname">
							{{scope.row.tplname}}
						</div>
						<div v-else>
							<span style="cursor: pointer;color: #E6A23C;"
								@click="update(scope.row,1)">暂未设置模板，请点击添加模板</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="模板操作" align="center" width="150">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: space-around;">
							<el-button type="warning" v-if="scope.row.tplname !='' "  plain size="mini" @click="update(scope.row,3)">修改</el-button>
							<el-button type="danger" v-if="scope.row.tplname !='' " plain size="mini" @click="deleteClick(scope.row,2)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total, prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px;text-align: center;">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="确定删除模板吗？" center :visible.sync="deleteDialog" width="400px">
			<div slot="footer">
				<el-button @click="deleteDialog = false">取 消</el-button>
				<el-button type="primary" @click="deleteConfirm()">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="假日模板" center :visible.sync="useDialog" width="500px">
			<div style="text-align: center;">
				<div>
					模板名称：
					<el-select v-model="useTemplateId" size="medium" placeholder="请选择模板" no-data-text="暂无模板,请先添加模板">
						<el-option v-for="item in templates" :key="item.id" :label="item.tplname" :value="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div slot="footer">
				<el-button @click="useDialog = false">取 消</el-button>
				<el-button type="primary" @click="use()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		sclTplList,
		tplList,
		setSclTpl
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				holidayData: [], //学校假日数据
				templates: [], //模板数据
				loading: false, //加载框
				useDialog: false, //学校选择模板弹框
				delId: '', //选择删除的模板id
				deleteDialog: false, //删除的弹框
				useTemplateId: '', //选择应用的模板id
				schoolId: '', //选择应用的学校id
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
        type: 0, // 操作类型
        ytplId: 0, // 原来假日模板id
			}
		},
		created() {
			this.getMachineTypeList(1); // 获取节假日应用列表
			this.getTemplateList(); // 获取节假日模板列表
		},
		methods: {
			async getTemplateList() { //获取节假日模板列表
				let res = await tplList();
				this.templates = res.data.list;
			},
			async getMachineTypeList(currpage) { //获取节假日应用列表
				this.loading = true;
				const data = {
					page: currpage,
					limit: this.pagesize
				};
				let res = await sclTplList(data);
				this.holidayData = res.data.list;
				this.CountNum = parseInt(res.data.countNum);
				this.currpage = currpage;
				this.loading = false;
			},
			async use() { //修改学校应用的模板接口
				const data = {
					tplId: this.useTemplateId,
					SchoolID: this.schoolId,
					type: this.type ,// 1:新增 3:修改
          ytplId: this.type == 1 ? 0 : this.ytplId // 原来的假日模板
				};
				let res = await setSclTpl(data); //接口请求
				if (res.status.code == 1) {
					this.$message.success('模板修改成功');
					this.useDialog = false;
					this.getMachineTypeList(1); // 获取节假日应用列表
				} else {
					this.$message.error(res.status.msg);
				}
			},
			update(row,type) { //修改学校应用的模板弹窗
				this.useDialog = true;
				this.schoolId = row.schoolId;
        this.type   = type;
        this.ytplId = row.wid
			},
			deleteClick(row) { //点击删除按钮
				this.delId = row.wid;
				this.schoolId = row.schoolId;
				this.deleteDialog = true; //打开修改弹框
			},
			async deleteConfirm() { //删除学校应用的模板
				const data = {
					tplId: this.delId,
					SchoolID: this.schoolId,
					type: 2 ,// 2:清空模板
          ytplId: 0 // 原来模板id
				};

				let res = await setSclTpl(data); //接口请求
				if (res.status.code == 1) {
					this.$message.success('模板删除成功');
					this.deleteDialog = false;
					this.getMachineTypeList(1); // 获取节假日应用列表
				} else {
					this.$message.error(res.status.msg);
				}
			},
			goHolidayTemplate() { //跳转去假日模板页面
				this.$router.push({
					name: 'HolidayTemplate'
				})
			},
			handleCurrentChange(val) { // 分页
				this.getMachineTypeList(val);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		padding: 20px 50px;

		.info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 20px;
			font-weight: bold;
			line-height: 50px;
		}
	}
</style>
